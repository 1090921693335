<template>
  <div>
    <DataTable
      v-bind="{
        list: orgsPage,
        pending,
        error,
        totalItems,
        pageSize,
        filters,
        header,
      }"
      @selected:filter="onFilterSelect"
      @openExportDialog="openExportDialog"
      @open:openExportDialog="openExportDialog"
    >
      <template #header:status="{ item: label }">
        <th class="text-center">
          {{ label }}
        </th>
      </template>
      <template #header:actions="{ item: label }">
        <th class="text-left pl-2 lg:pl-11">
          {{ label }}
        </th>
      </template>
      <template #col:name="{ item: org }">
        <td class="font-bold pl-2 lg:pl-11 truncate">
          {{ org.name }}
          <sup
            v-if="
              org.isSolutionPartner ||
              org.isFinancePartner ||
              org.isBuyer ||
              org.isTestAccount
            "
            >{{ flagOrg(org) }}</sup
          >
        </td>
      </template>

      <template #col:id="{ item: org }">
        <td>{{ org.id }}</td>
      </template>

      <template #col:address="{ item: org }">
        <td>{{ org.street }}, {{ org.zip }} {{ org.city }}</td>
      </template>

      <template #col:status="{ item: org }">
        <td :class="org.active ? 'text-primary' : 'text-darkGray'">
          <div
            class="mx-auto pl-3 w-[116px] cursor-pointer"
            @click="openStatusDialog(org)"
          >
            <Icon
              class="inline-block w-5 xl:w6"
              :name="org.active ? 'check' : 'cancel'"
            />
            <span class="font-semibold align-middle ml-2">
              {{ $t(`accountManagement.${org.active ? 'active' : 'locked'}`) }}
            </span>
          </div>
        </td>
      </template>
      <template #col:actions="{ item: org }">
        <td class="text-center pl-2 lg:pl-11">
          <checkbox v-model="toBeExported" :value="org.id"></checkbox>
        </td>
      </template>
    </DataTable>
    <ExportObjectsOrgDialog
      v-model="openExportObjectsOrg"
      v-bind="{ toBeExported }"
    ></ExportObjectsOrgDialog>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { DataTable } from '@/components/dataTable'
import { useListFetch } from '@/composition'
import { general } from '@/helpers'
import Checkbox from '@/components/form/Checkbox.vue'
import { ExportObjectsOrgDialog } from '@/components/dialog'

export default {
  components: {
    DataTable,
    Checkbox,
    ExportObjectsOrgDialog,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'organisations-list-fetch-error',
      })
    return useListFetch('organisations', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
    },
  },
  emits: ['update:table', 'update:exportList'],
  data() {
    return {
      activeFilter: 'all',
      toBeExported: [],
      openExportObjectsOrg: false,
    }
  },
  inject: ['newOrg'],
  computed: {
    activeOrgs() {
      return this.list.filter(u => u.active)
    },
    inactiveOrgs() {
      return this.list.filter(u => !u.active)
    },
    filteredOrgs() {
      switch (this.activeFilter) {
        case 'active':
          return this.activeOrgs
        case 'locked':
          return this.inactiveOrgs
        case 'pending':
          return []
        default:
          return this.list
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredOrgs() {
      const searchKeys = ['name', 'id', 'city', 'street']
      return general.searchOverKeys(
        this.filteredOrgs,
        searchKeys,
        this.searchTerm
      )
    },
    orgsPage() {
      return this.searchedFilteredOrgs.slice(...this.pageSlice)
    },
    filters() {
      return [
        {
          key: 'all',
          item: {
            label: this.$t('accountManagement.all'),
            active: this.activeFilter === 'all',
            number: this.list.length,
          },
        },
        {
          key: 'active',
          item: {
            label: this.$t('accountManagement.active'),
            active: this.activeFilter === 'active',
            number: this.activeOrgs.length,
          },
        },
        {
          key: 'locked',
          item: {
            label: this.$t('accountManagement.locked'),
            active: this.activeFilter === 'locked',
            number: this.inactiveOrgs.length,
          },
        },
        {
          key: 'pending',
          item: {
            label: this.$t('accountManagement.pending'),
            active: this.activeFilter === 'pending',
            number: 0,
          },
        },
      ]
    },
    header() {
      return ['name', 'id', 'address', 'status', 'actions'].map(key => ({
        key,
        item: this.$t(`accountManagement.organisations-${key}`),
      }))
    },
  },
  methods: {
    onFilterSelect(key) {
      this.activeFilter = key
    },

    flagOrg(org) {
      let flag = ''
      if (org.isSolutionPartner)
        flag =
          flag + ' ' + this.$t('accountManagement.solutionPartner-abbreviation')
      if (org.isFinancePartner)
        flag =
          flag + ' ' + this.$t('accountManagement.financePartner-abbreviation')
      if (org.isBuyer)
        flag = flag + ' ' + this.$t('accountManagement.isBuyer-abbreviation')
      if (org.isTestAccount)
        flag =
          flag + ' ' + this.$t('accountManagement.isTestAccount-abbreviation')
      return flag
    },
    openExportDialog() {
      this.openExportObjectsOrg = true
    },
  },
  watch: {
    toBeExported(newList) {
      this.$emit('update:exportList', newList)
    },
    searchedFilteredOrgs(newList) {
      this.$emit('update:table', newList.length)
    },
  },
}
</script>
